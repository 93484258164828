























































import Vue, { VueConstructor } from 'vue';
import OwnerMessageService from '@/services/owner/OwnerMessageService';
import StatusUpdater from '@/services/StatusUpdater';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import FormaterMixinVue from '@/mixins/FormaterMixin.vue';
import MessageDetailDto from '@/models/MessageDetailDto';
import MessageDetailHeader from '@/components/messages/MessageDetailHeader.vue';
import { MetaInfo } from 'vue-meta';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { MessageDetailHeader },
  mixins: [FormaterMixinVue],
  props: {
    messageId: {
      type: String,
    },
  },
  data() {
    return {
      message: new MessageDetailDto(),
      ready: false,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostMessageDetail.meta.title', { subject: this.message.subject }).toString(),
    };
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'OwnerMessageIndex' }, translationKey: 'pages.host.HostMessageDetail.breadcrumb.messages' },
        { text: this.message.subject },
      ]);
    },

    loadData() {
      OwnerMessageService.getDetail(this.messageId).then((res) => {
        this.message = res.value;
        this.ready = true;
        this.$store.dispatch('NextActivitiesStoreModule/removeNewMessage', this.messageId);
      });
      StatusUpdater.loadData();
    },
  },
  mounted() {
    // redirect if not logged in on Mounted
    const loggedIn = this.$store.state.AuthStoreModule.status.loggedIn;
    if (!loggedIn) {
      this.$router.push(process.env.VUE_APP_PUBLIC_BASE_URL + '/auth/relogin?redirect=' + this.$route.fullPath);
    } else {
      this.loadData();
      this.setBreadCrumb();
    }
  },
  computed: {
    canAnswer(): boolean {
      return this.$store.state.AuthStoreModule.user.id != this.message.senderId && this.message.senderId != null;
    },
    metaInformationObject(): {} {
      if (this.message.metaInformation === undefined) {
        return {};
      }
      return JSON.parse(this.message.metaInformation);
    },
  },
});
