export default class MessageDetailDto {
  body!: string;
  createdAt!: string;
  id!: string;
  isUnread!: boolean;
  sender!: string;
  subject!: string;
  senderId!: string;
  metaInformation!: string;
  linkToOrderId!: string;
  messageAttachments!: MessageAttachments;
}

class MessageAttachments {
  id!: string;
  text!: string;
  url!: string;
}
