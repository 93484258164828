var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('CCard',[_c('CCardHeader',[_c('MessageDetailHeader',{attrs:{"message":_vm.message}})],1),_c('CCardBody',{domProps:{"innerHTML":_vm._s(_vm.message.body)}}),_c('CCardFooter',[(_vm.metaInformationObject.orderId)?_c('span',[_c('router-link',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.messages.OwnerMessageDetail.orderLink'),expression:"'pages.owner.messages.OwnerMessageDetail.orderLink'"}],attrs:{"to":{ name: 'OwnerOrderDetail', params: { orderId: _vm.metaInformationObject.orderId } }}}),_c('br')],1):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('pages.owner.messages.OwnerMessageDetail.sender', { sender: _vm.message.sender }))+" "+_vm._s(_vm.$t('pages.owner.messages.OwnerMessageDetail.createdAt', { createdAt: _vm.formatIsoDateTimeTz(_vm.message.createdAt, 'p P'), }))+" "),(_vm.canAnswer)?_c('CButton',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.messages.OwnerMessageDetail.answer'),expression:"'pages.owner.messages.OwnerMessageDetail.answer'"}],attrs:{"to":{
        name: 'OwnerCreateMessage',
        params: { receiverId: _vm.message.senderId, orgSubject: _vm.message.subject },
      },"color":"primary"}}):_vm._e(),_c('div',{staticClass:"buttonlist"},[(_vm.message.linkToOrderId)?_c('CButton',{directives:[{name:"t",rawName:"v-t",value:('pages.owner.messages.OwnerMessageDetail.orderLink'),expression:"'pages.owner.messages.OwnerMessageDetail.orderLink'"}],attrs:{"to":{
          name: 'OwnerOrderDetail',
          params: {
            orderId: _vm.message.linkToOrderId,
          },
        },"color":"secondary"}}):_vm._e(),_vm._l((_vm.message.messageAttachments),function(attachment){return _c('CButton',{key:attachment.id,attrs:{"color":"primary","href":attachment.url}},[_vm._v(" "+_vm._s(attachment.text)+" ")])})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }